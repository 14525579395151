import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  const completedProjects = props.data?.filter((item) => item.status === "completed") || [];
  const inProgressProjects = props.data?.filter((item) => item.status === "in-progress") || [];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>See for your self</h2>
        </div>

        {/* Completed Projects Section */}
        <div className="section-title">
          <h3>Completed Projects</h3>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {completedProjects.length > 0
              ? completedProjects.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "No completed projects available."}
          </div>
        </div>

        {/* Projects in Progress Section */}
        <div className="section-title">
          <h3>Projects in Progress</h3>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {inProgressProjects.length > 0
              ? inProgressProjects.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "No projects in progress at the moment."}
          </div>
        </div>
      </div>
    </div>
  );
};
